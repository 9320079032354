import {Col, Container, Row} from "react-bootstrap";
import {FaCheck, FaCheckCircle} from "react-icons/fa";

function Thanks() {
    return(
        <Container className={"h-100"}>
            <Row className={"justify-content-center h-100 align-items-center"}>
                <Col md={12} className={"text-center"}>
                    <FaCheckCircle size={90} color={"#4bae4f"} />
                    <h4 className={"mt-3 fw-bold"}>سيتم معالجة الطلب بأقرب وقت...</h4>
                </Col>
            </Row>
        </Container>
    )
}
export default Thanks
