import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import './App.css';
import {Route,  Routes} from "react-router-dom";
import Home from "./layout/home";
import Thanks from "./layout/thanks";

function App() {
  return (
    <div className="App" id={"top"}>
        <Routes>
            <Route path={"/mt/:id"} index element={<Home />} />
            <Route path={"/thank-you"} index element={<Thanks />} />
        </Routes>
    </div>
  );
}

export default App;
